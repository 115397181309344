import Experience from "../Experience";
import * as THREE from 'three'
import { gsap } from 'gsap';
export default class FreseniusSpace
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.shape1 = null
        this.shape2 = null
        this.shape3 = null
        this.door=null
        this.door2= null
        this.transprencyIsActive=false
        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('DISPLAY')
        }
        //Setup
        this.resource = this.resources.items.FreseniusModelV7
        // this.humanResource = this.resources.items.RealisticHuman1
        // console.log(this.humanResource);
        this.setScrollRoom()
        this.setModel()
        this.addVideo()
        this.addImages()
        this.setAnimation()
        this.setRayCaster()
        this.setTransparencyImage()
    }

    setModel()
    {
        this.model = this.resource.scene
        
        // this.model.scale.set(0.02, 0.02,0.02)
        this.scene.add(this.model)
        // console.log(this.model);
        this.model.traverse((child)=>
        {
            if(child instanceof THREE.Mesh)
            // console.log(child.name);
            {   if (child.name =='pCube2'){
                console.log(child);
                this.door = child;
                
            }    
            if (child.name =='pCube3'){
                console.log(child);
                this.door2 = child;
            }             
                child.castShadow= true
                child.receiveShadow=true
            }
        })
    }
    // setHuman()
    // {
    //     this.model = this.humanResource.scene
    //    this.model.scale.set(0.015,0.015,0.015)
    //    this.model.scale.set(0.1,0.1,0.1)
    //     this.scene.add(this.model)
        
    //     this.model.traverse((child)=>
    //     {
    //         if(child instanceof THREE.Mesh)
    //         {
                
    //             child.castShadow= true
    //             child.receiveShadow=true
    //         }
    //     })
    // }
    setScrollRoom()
    {
        //material
        this.material = new THREE.MeshStandardMaterial(
            {
                color:'white',
               
        
            })
            const objectsDistance = 4
            const mesh1 = new THREE.Mesh(
                new THREE.TorusGeometry(1,0.4,16,60),
                this.material
            )
            
            const mesh2 = new THREE.Mesh(
                new THREE.ConeGeometry(1,2,32),
                this.material
            )
            const mesh3 = new THREE.Mesh(
                new THREE.TorusKnotGeometry(0.8,0.35,100,16),
                this.material
            )  
            mesh1.scale.set(0.5,0.5,0.5)
            mesh2.scale.set(0.5,0.5,0.5)
            mesh3.scale.set(0.5,0.5,0.5)

            mesh1.position.set(36,-0.7,-7.5)
            mesh2.position.set(36,-0.7,-8.5)
            mesh3.position.set(36,-0.7,-9.5)

            this.shape1= mesh1
            this.shape2= mesh2
            this.shape3= mesh3

            this.scene.add(mesh1,mesh2,mesh3)

    }

    addVideo()
    {
        this.video = document.getElementById('video')
        // this.videoTransparency = document.getElementById('videoTransparency')
        this.video.volume = 0.3
        this.video.play()
        this.videoTexture = new THREE.VideoTexture(this.video)
        this.videoTexture.needsUpdate = true
        this.videoTexture.minFilter = THREE.LinearFilter
        this.videoTexture.magFilter = THREE.LinearFilter


        // this.videoTransparency.volume = 0.3
        // this.videoTransparency.play()
        // this.videoTexture2 = new THREE.VideoTexture(this.videoTransparency)
        // this.videoTexture2.format = THREE.RGBAFormat;
        // this.videoTexture2.needsUpdate = true
        // this.videoTexture2.minFilter = THREE.LinearFilter
        // this.videoTexture2.magFilter = THREE.LinearFilter
        
        this.movieMaterial = new THREE.MeshBasicMaterial({
            map:this.videoTexture,
            side: THREE.FrontSide,
            toneMapped:false
        })
        // this.movieMaterial2 = new THREE.MeshBasicMaterial({
        //     map:this.videoTexture2,
        //     transparent:true,
        //     side: THREE.FrontSide,
        //     toneMapped:false
        // })



        this.movieGeometry = new THREE.PlaneGeometry(2.33,1.32)
        this.movieGeometry2 = new THREE.PlaneGeometry(1,2)
        this.movieScreen = new THREE.Mesh(this.movieGeometry,this.movieMaterial)
        // this.movieScreen2 = new THREE.Mesh(this.movieGeometry2,this.movieMaterial2)
        this.scene.add(this.movieScreen)

        this.movieScreen.position.set(1.488,2,-1.25)
        // this.movieScreen2.position.set(-8.2,1.5,-29)
        if(this.debug.active){
   
            this.debug.ui.add(this.movieScreen.position,'x').min(-10).max(10).step(0.001).name('Screen Position x')
            this.debug.ui.add(this.movieScreen.position,'y').min(-10).max(20).step(0.001).name('Screen Position y')
            this.debug.ui.add(this.movieScreen.position,'z').min(-20).max(20).step(0.001).name('Screen Position z')
            this.debug.ui.add(this.movieScreen.rotation,'y').min(-10).max(20).step(0.001).name('Screen Position y')

            this.debug.ui.add(this.movieScreen.material,'toneMapped')
            // this.debug.ui.add(this.movieScreen.geometry,'x').min(-10).max(10).step(0.001).name('Screen Position x')
            // this.debug.ui.add(this.movieScreen.position,'y').min(-10).max(20).step(0.001).name('Screen Position y')
            // this.debug.ui.add(this.movieScreen.position,'z').min(-20).max(20).step(0.001).name('Screen Position z')
            // this.debug.ui.add(this.movieScreen.rotation,'y').min(-10).max(20).step(0.001).name('Screen Position y')  
        }    
    }

    addImages(){

        this.image = this.resources.items.docImage
        this.doctorImage = this.resources.items.docImage2
        this.suitManImage = this.resources.items.suitMan
        this.n = '0'
        
        console.log(this.image);
      

        this.planeMaterial = new THREE.MeshBasicMaterial({
            // side: THREE.FrontSide,
            side: THREE.DoubleSide,
            map: this.image,
            color:'white',
            toneMapped:false,
            transparent:true,
        })
        
        this.texture = new THREE.Texture()
        this.planeGeometry = new THREE.PlaneGeometry(1,2)
        this.planeScreen1 = new THREE.Mesh(this.planeGeometry,this.planeMaterial)
        this.planeScreen3 = new THREE.Mesh(this.planeGeometry,this.planeMaterial)
        this.planeScreen4 = new THREE.Mesh(this.planeGeometry,this.planeMaterial)

        this.planeScreen1.castShadow=true;
        this.planeScreen3.castShadow=true;
        this.planeScreen4.castShadow=true;

        // this.planeScreen1.receiveShadow=true;
        // this.planeScreen2.receiveShadow=true;
        // this.planeScreen3.receiveShadow=true;
        // this.planeScreen4.receiveShadow=true;


        // this.scene.add(this.planeScreen1,this.planeScreen2,this.planeScreen3,this.planeScreen4)
       


        this.planeScreen1.position.set(7.3,1.8,-29)
        this.planeScreen1.rotation.y = 0.8
        this.planeScreen3.position.set(-7.6,1.48,-6.5)
        this.planeScreen4.position.set(12.3,1.5,-6.7)
        
        
        this.planeScreen3.rotation.y = Math.PI * 0.5
        this.planeScreen4.rotation.y = -Math.PI * 0.5

         //cube stands for images
         this.cubeMaterial = new THREE.MeshStandardMaterial()
         this.cubeGeometry = new THREE.BoxGeometry(1,1,1)
         this.cube1 = new THREE.Mesh(this.cubeGeometry,this.cubeMaterial)
         this.cube2 = new THREE.Mesh(this.cubeGeometry,this.cubeMaterial)
       

         this.cube1.position.set(-7.5,0,-6.5)
         this.cube2.position.set(12.3,0,-6.7)
      
         this.cube1.castShadow = true
         this.cube2.castShadow = true
         

        this.imageGroup = new THREE.Group()
        this.imageGroup2 = new THREE.Group()
        
        this.imageGroup.add(this.planeScreen3,this.cube1)
        this.imageGroup2.add(this.planeScreen4,this.cube2)
       

        this.scene.add(this.imageGroup,this.imageGroup2)



        if(this.debug.active){
            this.debug.ui.add(this.imageGroup.position,'x').min(-30).max(30).step(0.001).name('plane Position x')
            this.debug.ui.add(this.imageGroup.position,'y').min(-10).max(20).step(0.001).name('plane Position y')
            this.debug.ui.add(this.imageGroup.position,'z').min(-30).max(30).step(0.001).name('plane Position z')
            this.debug.ui.add(this.imageGroup.rotation,'y').min(-10).max(20).step(0.001).name('plane rotation ')

            this.debug.ui.add(this.planeScreen4.position,'x').min(-30).max(30).step(0.001).name('plane2 Position x')
            this.debug.ui.add(this.planeScreen4.position,'y').min(-10).max(20).step(0.001).name('plane2 Position y')
            this.debug.ui.add(this.planeScreen4.position,'z').min(-30).max(30).step(0.001).name('plane2 Position z')
            this.debug.ui.add(this.planeScreen4.rotation,'y').min(-10).max(20).step(0.001).name('plane2 rotation')
        }

       


    }

    changeImage(a){
       
        switch (a) {
            case '1':
                this.cube1.material.color.set('#ff0000')
                this.planeMaterial.map = this.suitManImage
                break;
            case '2':
                this.cube1.material.color.set('#0000ff')
                this.planeMaterial.map = this.doctorImage
                break;
            default:
                this.cube1.material.color.set('#ffffff')
                this.planeMaterial.map = this.image
                break;
        }
    }

    setRayCaster(){

         //Raycaster
         this.raycaster = new THREE.Raycaster()
         this.currentIntersect = null
         this.VideoIsBig = false
         this.videoIsReady = true
         this.planeIsActive = false
         this.popup=document.getElementById('popup')
         this.popupInvestor=document.getElementById('popupInvestor')
         this.popupDoctor=document.getElementById('popupDoctor')
         //mouse
        this.mouse = new THREE.Vector2()
        window.addEventListener('mousemove',(_event) =>{
        this.mouse.x = _event.clientX/this.experience.sizes.width  *2 -1 
        this.mouse.y = - (_event.clientY/this.experience.sizes.height)*2+1            
        })

        window.addEventListener('click',()=>{
            //se puede usar un switch() en c¡vez de un if
            if(this.planeIsActive){
                this.popup.classList.remove('ended')
                this.popupInvestor.classList.remove('ended')
                this.popupDoctor.classList.remove('ended')
                this.planeIsActive=false
            }
            if(this.currentIntersect){
               
                if(this.currentIntersect.object === this.movieScreen){
                    console.log('click on screen');   

                    if(!this.VideoIsBig&&this.videoIsReady){
                        this.videoIsReady= false
                        gsap.to(
                            this.movieScreen.position,{
                                duration: 2.5,
                                ease: 'power2.inOut',
                                z: '+=4' 
                            }) 
                            gsap.to(
                                this.movieScreen.position,{
                                    duration: 2.5,
                                    ease: 'power2.inOut',
                                    y: '-=0.3' 
                                }) 
                        this.video.muted = !this.video.muted;
                        this.VideoIsBig=true 
                        setTimeout(()=>{
                            this.videoIsReady= true
                        },2800) 
                        
                    }else{
                        if(this.videoIsReady){
                            this.videoIsReady= false
                            gsap.to(
                            this.movieScreen.position,{
                                duration: 2.5,
                                ease: 'power2.inOut',
                                z: '-=4' 
                            }) 
                            gsap.to(
                                this.movieScreen.position,{
                                    duration: 2.5,
                                    ease: 'power2.inOut',
                                    y: '+=0.3' 
                                }) 
                        this.video.muted = !this.video.muted;        
                        this.VideoIsBig=false 
                        setTimeout(()=>{
                            this.videoIsReady= true
                        },2800) 
                         }
                        
                    }

                }
                if(this.currentIntersect.object === this.planeScreen3||this.currentIntersect.object === this.planeScreen4){
                    switch (this.planeMaterial.map) {
                        case this.suitManImage:
                            this.popupInvestor.classList.add('ended')
                            this.popupInvestor.style.transform = ''
                            break;
                    
                        case this.doctorImage:
                            this.popupDoctor.classList.add('ended')
                            this.popupDoctor.style.transform = ''
                            break;
                        
                        default:
                            this.popup.classList.add('ended')
                            this.popup.style.transform = ''
                            break;
                    }
                    // this.popup=document.getElementById('popup')
                    // this.popup.classList.add('ended')
                    // this.popup.style.transform = ''
                    this.planeIsActive = true

                }
            }})
    }

    setTransparencyImage(){
        // this.transparentImage = this.resources.items.transparentImage0
        this.n=0
        this.textureItem = 'transparentImage'+this.n
        this.transparentImage = this.resources.items[this.textureItem]
       
        this.planeMaterial2 = new THREE.MeshBasicMaterial({
            // side: THREE.FrontSide,
            side: THREE.DoubleSide,
            map: this.transparentImage,
            color:'white',
            toneMapped:false,
            transparent:true,
        })

        this.planeGeometry = new THREE.PlaneGeometry(1,2)
        this.planeScreen2 = new THREE.Mesh(this.planeGeometry,this.planeMaterial2)
        this.planeScreen2.position.set(-8.2,1.5,-29)
        this.planeScreen2.rotation.y = Math.PI 
        this.planeScreen2.material.needsUpdate=true
        this.firstIteration=true

        this.cubeMaterial = new THREE.MeshStandardMaterial()
        this.cubeGeometry = new THREE.BoxGeometry(1,1,1)
        this.transparentFrames = []
        // for(let i = 0;i<3;i++){
        //     let plane  = new THREE.Mesh(this.planeGeometry,this.planeMaterial2)
        //     this.textureItem = 'transparentImage'+i
        //     plane.material.map = this.textureItem
        //     plane.position.set(-8.2,1.5,-29)
        //     plane.rotation.y = Math.PI 
        //     plane.material.needsUpdate=true
        //     // this.plane.material.visible=false 
        //     // if(i==0)this.plane.material.visible=true
        //     this.transparentFrames.push(plane)
            
        //     if(plane.isMesh)this.scene.add(plane)

        // }
        this.cube3 = new THREE.Mesh(this.cubeGeometry,this.cubeMaterial)
        this.cube3.position.set(-8.2,0,-29)
        this.cube3.castShadow = true
        this.imageGroup3 = new THREE.Group()
         this.imageGroup3.add(this.planeScreen2,this.cube3)
        this.scene.add(this.imageGroup3)
    }
    activateTransparencyAnimation(){
        this.transprencyIsActive= !this.transprencyIsActive
        this.skip=false
    }

    setAnimation()
    {
      
    }

    update()
    {
       if(this.videoTexture){        
        this.movieScreen.material.needsUpdate= true
        this.videoTexture.update()
       }
       if(this.videoTexture2){        
        this.movieScreen2.material.needsUpdate= true
        this.videoTexture2.update()
       }
        

       //Raycasting
      
        //cast a ray 
        this.raycaster.setFromCamera(this.mouse,this.experience.camera.instance)
        this.objectsToTest = [this.movieScreen,this.imageGroup,this.imageGroup2]
        this.intersects = this.raycaster.intersectObjects(this.objectsToTest)

        //

        if(this.intersects.length)
        {
            // console.log('entra');
            //enter event 
            if(!this.currentIntersect){
                document.getElementsByTagName('body')[0].style.cursor = 'pointer' 
                // console.log('mouse enter');
            }
            //update intersect
            this.currentIntersect= this.intersects[0]
           
        }else{
            //enter event 
            if(this.currentIntersect){
                document.getElementsByTagName('body')[0].style.cursor = 'default' 
                // console.log('mouse leave');
            }
            //update intersect
            this.currentIntersect= null
        }
        

        // animate image 
        if(this.transprencyIsActive){ 

            // this.planeScreen2.material.needsUpdate=true
            if(!this.skip){
               if(this.firstIteration){
                console.log('transparency load loading ');
                // for(let i=0;i<300;i++){
                //     this.textureItem = 'transparentImage'+i
                //     this.transparentImage = this.resources.items[this.textureItem]
                //     this.planeScreen2.material.map =this.transparentImage
                // }
                if(this.n<50){
                    this.textureItem = 'transparentImage'+this.n
                    this.transparentImage = this.resources.items[this.textureItem]
                    this.planeScreen2.material.map =this.transparentImage
                    this.n++
                  
               }else{
                this.n=0
                 } 
                this.firstIteration=false
                console.log('transparency loaded '); 
                // if(this.n<300){
                    
                //     this.textureItem = 'transparentImage'+this.n
                //     this.transparentImage = this.resources.items[this.textureItem]
                //     this.planeScreen2.material.map =this.transparentImage
                //     this.n++
                    
                // }else{
                //     this.firstIteration=false  
                //     console.log('transparency loaded ');
                // } 
                
               }else{
                if(this.n<50){
                    this.textureItem = 'transparentImage'+this.n
                    this.transparentImage = this.resources.items[this.textureItem]
                    this.planeScreen2.material.map =this.transparentImage
                    this.n++
               }else{
                this.n=0
                 } 
               }
                
             
                // this.transparentFrames[this.n].material.visible=true
                // this.transparentFrames[this.n].material.needsUpdate=true
                // if(this.n>0)this.transparentFrames[this.n-1].material.visible=false
                // this.scene.add(this.transparentFrames[this.n])

                
                // if(this.transparentFrames[this.n].isMesh){
                    
                //     console.log(this.transparentFrames[this.n]);
                    
                //     // console.log(this.transparentFrames[this.n]);
                // }
                  
               this.skip=true
               
                
            }else this.skip=false
        }
    }
       
}