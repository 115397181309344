import Experience from "../Experience"
import * as THREE from 'three'

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.setSunLight()
        this.setReceptionLight()
        this.setEnvironmentMap()
        this.setEnviromentLight()
        // this.setHeliosLight()
        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('Lights')
        }
    }




setEnviromentLight(){
    this.envLight = new THREE.AmbientLight('#ffffff', 1.75)
    this.scene.add(this.envLight)
    if(this.debug.active){
        this.debug.ui.add(this.envLight,'intensity').min(0).max(2).step(0.001).name('ENV Light instensity')
    }
}

setSunLight()
{
    
    // this.sunLight = new THREE.DirectionalLight('#ffffff', 4)
    this.sunLight = new THREE.DirectionalLight('#ffffff', 2.6)
    this.sunLight.castShadow = true
    // this.sunLight.shadow.camera.far = 15
    this.sunLight.shadow.camera.top = 15
    this.sunLight.shadow.camera.right = 15
    this.sunLight.shadow.camera.left = -15
    this.sunLight.shadow.camera.bottom = -15
    this.sunLight.shadow.mapSize.set(1024*4, 1024*4)
    this.sunLight.shadow.normalBias = 0.05
    this.sunLight.position.set(-1,10, 6)
    this.scene.add(this.sunLight)
    // this.scene.add(new THREE.AxesHelper())
    // this.sunHelper = new THREE.CameraHelper(this.sunLight.shadow.camera)
    // this.scene.add(this.sunHelper)
    //debug
    if(this.debug.active){
   
    this.debug.ui.add(this.sunLight,'intensity').min(-10).max(10).step(0.001).name('Light instensity')
    this.debug.ui.add(this.sunLight.position,'x').min(-10).max(10).step(0.001).name('Light Position x')
    this.debug.ui.add(this.sunLight.position,'y').min(-10).max(20).step(0.001).name('Light Position y')
    this.debug.ui.add(this.sunLight.position,'z').min(-20).max(20).step(0.001).name('Light Position z')
    }
}

setReceptionLight()
{
    // this.receptionLight = new THREE.PointLight('#0000ff',30)
    this.receptionLight = new THREE.PointLight('#0000ff',30)
    this.receptionLight.castShadow = true
    this.receptionLight.shadow.camera.far = 40
    this.receptionLight.shadow.mapSize.set(1024, 1024)
    this.receptionLight.shadow.normalBias = 0.05
    this.receptionLight.position.set(1.3,0.1,-1)
    this.scene.add(this.receptionLight)
    this.receptionLightHelper = new THREE.PointLightHelper(this.receptionLight)
   
    // this.scene.add(this.receptionLightHelper)
    //debug
    if(this.debug.active){
   
        this.debug.ui.add(this.receptionLight,'intensity').min(-10).max(40).step(0.001).name('reception Light instensity')
        this.debug.ui.add(this.receptionLight.position,'x').min(-10).max(10).step(0.001).name('reception Light Position x')
        this.debug.ui.add(this.receptionLight.position,'y').min(-10).max(10).step(0.001).name('reception Light Position y')
        this.debug.ui.add(this.receptionLight.position,'z').min(-2).max(2).step(0.001).name('reception Light Position z')
        }
    
}

setEnvironmentMap()
{
    this.environmentMap={}
    this.environmentMap.intensity =0.3
    this.environmentMap.texture = this.resources.items.mapSkyTexture
    this.environmentMap.texture.encoding = THREE.sRGBEncoding
    // this.scene.environment = this.environmentMap.texture
    // this.scene.background = this.environmentMap.texture

    

    this.setEnvironmentMap.updateMaterial = () =>
    {
        this.scene.traverse((child) =>
        {
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                child.material.envMap = this.environmentMap.texture
                child.material.envMapIntensity = this.environmentMap.intensity
                child.material.needsUpdate=true
               
                if(child.name=="Fresenius_Helios"){     

                    child.material.map = this.resources.items.freseniusMCTexture                                 
                    // console.log(child.name);
                }
            }
        })
    }
    this.setEnvironmentMap.updateMaterial()
}
setHeliosLight()
{
    this.heliosLight = new THREE.DirectionalLight('#ffffff', 4)
    
    this.heliosLight.castShadow = true
    this.heliosLight.lookAt(9,0,-29)
    this.heliosLight.position.set(9,1.8,-29)
    this.heliosLight.shadow.mapSize.set(1024*4, 1024*4)
    
    this.heliosHelper = new THREE.CameraHelper(this.heliosLight.shadow.camera)
    this.heliosLightHelper = new THREE.DirectionalLightHelper(this.heliosLight)
   
    this.scene.add(this.heliosLight,this.heliosLightHelper,this.heliosHelper)
     if(this.debug.active){
   
        this.debug.ui.add(this.heliosLight,'intensity').min(-10).max(40).step(0.001).name('helios Light instensity')
        this.debug.ui.add(this.heliosLight.position,'x').min(-10).max(40).step(0.001).name('helios Light Position x')
        this.debug.ui.add(this.heliosLight.position,'y').min(-10).max(10).step(0.001).name('helios Light Position y')
        this.debug.ui.add(this.heliosLight.shadow.camera.rotation,'y').min(-10).max(10).step(0.001).name('helios Light rosition y')
        this.debug.ui.add(this.heliosLight.position,'z').min(-40).max(2).step(0.001).name('helios Light Position z')
        }
}

}