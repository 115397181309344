import { gsap } from "gsap";
import Experience from "../Experience";
import * as THREE from 'three'
import World from "../World/World";
export default class Events
{
    constructor()
    {
        this.experience = new Experience()
        this.world = this.experience.world
        this.cam = this.experience.camera
        this.room1 = document.getElementById('Cam1')
        this.room2 = document.getElementById('Cam2')
        this.roomKabi = document.getElementById('CamKabi')
        this.roomHelios = document.getElementById('CamHelios')
        this.roomVamed = document.getElementById('CamVamed')
        this.select = document.getElementById('select')
        this.roomMC = document.getElementById('CamMC')
        this.room3 = document.getElementById('Cam3')
        this.room0 = document.getElementById('Cam0')
        this.enterExp = document.getElementById('expButton')
        this.activateAnimation = document.getElementById('activateAnimation')
        //Events Listeners
        //clicks 
        this.room1.addEventListener('click', ()=>{         
            this.moveCam('room1')
        })
        this.activateAnimation.addEventListener('click', ()=>{         
            this.experience.world.freseniusDisplay.activateTransparencyAnimation()
        })
        this.enterExp.addEventListener('click', ()=>{         
            this.moveCam('room1')
        })
        this.room2.addEventListener('click', ()=>{         
            this.moveCam('room2')
        })
        this.room3.addEventListener('click', ()=>{         
            this.moveCam('room3')
        })
        this.room0.addEventListener('click', ()=>{         
            this.moveCam('reception')
        })
        this.roomKabi.addEventListener('click', ()=>{         
            this.moveCam('kabi')
        })
        this.roomHelios.addEventListener('click', ()=>{         
            this.moveCam('helios')
        })
        this.roomVamed.addEventListener('click', ()=>{         
            this.moveCam('Vamed')
        })
        this.roomMC.addEventListener('click', ()=>{         
            this.moveCam('MC')
        })

        //hovers
        this.room1.addEventListener('mouseenter', () =>{  
           this.changeLight('room1')
           
        });
        this.room1.addEventListener('mouseleave', ()=> {
            this.changeLight('none')
        }); 
        this.roomKabi.addEventListener('mouseenter', () =>{  
            this.changeLight('room2')
            
         });
         this.roomKabi.addEventListener('mouseleave', ()=> {
             this.changeLight('none')
         }); 
         this.roomHelios.addEventListener('mouseenter', () =>{  
            this.changeLight('room3')
            
         });
         this.roomHelios.addEventListener('mouseleave', ()=> {
             this.changeLight('none')
         }); 
         


    }

    update()
    {
        
        
    }

    moveCam(room)
    {
        switch (room) {
            case 'room1':
               
                this.roomMC.style.display='none'

                this.room1.innerHTML='SCENE 1'
                this.room0.style.display='inline-block'
                this.room1.style.display='none'
                this.room2.style.display='inline-block'
                this.room3.style.display='inline-block'  
                this.roomVamed.style.display='none' 
                this.roomHelios.style.display='none' 
                this.roomKabi.style.display='none' 
                this.enterExp.style.display='none' 
                this.select.style.display='none'  
                this.activateAnimation.style.display='none'


                document.getElementById('SCENE1').style.display='block'
                document.getElementById('SCENE2').style.display='none'
                document.getElementById('SCENE3').style.display='none'
                document.getElementById('RoomHelios').style.display='none'
                document.getElementById('RoomKabi').style.display='none'
                document.getElementById('RoomMC').style.display='none'
                document.getElementById('RoomVamed').style.display='none'
                document.getElementById('lorem2').classList.remove('active2')
                document.getElementById('lorem3').classList.remove('active3')
                document.getElementById('lorem1').classList.add('active1')
                
                this.room1.style.display='none'
                this.cam.cameraAnimations.CameraToRoom1()

                break;
            case 'room2':
                this.room0.style.display='inline-block'
                this.room1.style.display='inline-block'
                this.room2.style.display='none'
                this.room3.style.display='none' 
                this.roomHelios.style.display='none' 
                this.roomKabi.style.display='none' 
                this.roomVamed.style.display='none' 
                this.roomMC.style.display='inline-block' 
                this.enterExp.style.display='none' 
                this.select.style.display='none' 
                this.activateAnimation.style.display='none'

                document.getElementById('SCENE2').style.display='block'
                document.getElementById('SCENE1').style.display='none'
                document.getElementById('SCENE3').style.display='none'
                document.getElementById('RoomHelios').style.display='none'
                document.getElementById('RoomKabi').style.display='none'
                document.getElementById('RoomMC').style.display='none'
                document.getElementById('RoomVamed').style.display='none'
                document.getElementById('lorem1').classList.remove('active1')
                document.getElementById('lorem3').classList.remove('active3')
                document.getElementById('lorem2').classList.add('active2')
                this.cam.cameraAnimations.CameraToRoom2()
                    
                break;
            case 'room3':
                this.room0.style.display='inline-block'
                this.room1.style.display='inline-block'
                this.room2.style.display='none'
                this.room3.style.display='none' 
                this.roomHelios.style.display='none' 
                this.roomKabi.style.display='none' 
                this.roomMC.style.display='none' 
                this.roomVamed.style.display='inline-block' 
                this.enterExp.style.display='none' 
                this.select.style.display='none'  
                this.activateAnimation.style.display='none'
                document.getElementById('SCENE3').style.display='block'
                document.getElementById('SCENE2').style.display='none'
                document.getElementById('SCENE1').style.display='none'
                document.getElementById('RoomHelios').style.display='none'
                document.getElementById('RoomKabi').style.display='none'
                document.getElementById('RoomMC').style.display='none'
                document.getElementById('RoomVamed').style.display='none'
                document.getElementById('lorem1').classList.remove('active1')
                document.getElementById('lorem2').classList.remove('active2')
                document.getElementById('lorem3').classList.add('active3')
                this.cam.cameraAnimations.CameraToRoom3()
                
                break;
            case 'reception':
                this.room1.innerHTML='Room FSE'
                this.room0.style.display='none'
                this.room2.style.display='none'
                this.room3.style.display='none'
                this.room1.style.display='none'
                this.select.style.display='inline-block'  
                this.roomVamed.style.display='inline-block' 
                this.roomMC.style.display='inline-block' 
                this.roomHelios.style.display='inline-block' 
                this.roomKabi.style.display='inline-block' 
                this.activateAnimation.style.display='none'
                this.enterExp.style.display='inline-block' 

                document.getElementById('SCENE1').style.display='none'
                document.getElementById('SCENE2').style.display='none'
                document.getElementById('SCENE3').style.display='none'
                document.getElementById('RoomHelios').style.display='none'
                document.getElementById('RoomKabi').style.display='none'
                document.getElementById('RoomMC').style.display='none'
                document.getElementById('RoomVamed').style.display='none'
                document.getElementById('lorem1').classList.remove('active1')
                document.getElementById('lorem2').classList.remove('active2')
                document.getElementById('lorem3').classList.remove('active3')

                this.cam.cameraAnimations.CameraToReception()
                
                break;    
              case 'helios':
                this.room1.innerHTML='Room FSE'
                this.room0.style.display='inline-block'
                this.room1.style.display='none'
                this.room2.style.display='none'
                this.room3.style.display='none' 
               this.enterExp.style.display='none' 
               this.select.style.display='none'  
               this.activateAnimation.style.display='none'

                document.getElementById('SCENE1').style.display='none'
                document.getElementById('SCENE2').style.display='none'
                document.getElementById('SCENE3').style.display='none'
                document.getElementById('RoomHelios').style.display='block'
                document.getElementById('RoomKabi').style.display='none'
                document.getElementById('RoomMC').style.display='none'
                document.getElementById('RoomVamed').style.display='none'
                document.getElementById('lorem1').classList.remove('active1')
                document.getElementById('lorem2').classList.remove('active2')
                document.getElementById('lorem3').classList.remove('active3')

                this.cam.cameraAnimations.CameraToHelios()
                
                break;    
                case 'kabi':
                    this.room1.innerHTML='Room FSE'
                    this.room0.style.display='inline-block'
                    this.room1.style.display='none'
                    this.room2.style.display='none'
                    this.room3.style.display='none' 
                   this.enterExp.style.display='none' 
                   this.select.style.display='none'  
                   this.activateAnimation.style.display='inline-block'

                    document.getElementById('SCENE1').style.display='none'
                    document.getElementById('SCENE2').style.display='none'
                    document.getElementById('SCENE3').style.display='none'
                    document.getElementById('RoomKabi').style.display='block'
                    document.getElementById('RoomHelios').style.display='none'
                    document.getElementById('RoomMC').style.display='none'
                    document.getElementById('RoomVamed').style.display='none'
                    document.getElementById('lorem1').classList.remove('active1')
                    document.getElementById('lorem2').classList.remove('active2')
                    document.getElementById('lorem3').classList.remove('active3')
    
                    this.cam.cameraAnimations.CameraToKabi()
                    
                    break;  
                    case 'Vamed':
                    // this.room1.innerHTML='Room FSE'
                    this.room0.style.display='inline-block'
                    this.room1.style.display='none'
                    this.room2.style.display='none'
                    this.room3.style.display='inline-block' 
                    this.roomVamed.style.display='none'
                    this.enterExp.style.display='none' 
                    this.select.style.display='none'  
                    this.activateAnimation.style.display='none'

                    document.getElementById('SCENE1').style.display='none'
                    document.getElementById('SCENE2').style.display='none'
                    document.getElementById('SCENE3').style.display='none'
                    document.getElementById('RoomKabi').style.display='none'
                    document.getElementById('RoomVamed').style.display='block'
                    document.getElementById('RoomHelios').style.display='none'
                    document.getElementById('RoomMC').style.display='none'
                    document.getElementById('lorem1').classList.remove('active1')
                    document.getElementById('lorem2').classList.remove('active2')
                    document.getElementById('lorem3').classList.remove('active3')
    
                    this.cam.cameraAnimations.CameraToVamed()
                    
                    break;   
                    case 'MC':
                        // this.room1.innerHTML='Room FSE'
                        this.room0.style.display='inline-block'
                        this.room1.style.display='none'
                        this.room2.style.display='inline-block'
                        this.room3.style.display='none' 
                        this.roomMC.style.display='none'
                        this.enterExp.style.display='none' 
                        this.select.style.display='none'  
                        this.activateAnimation.style.display='none'

                        document.getElementById('SCENE1').style.display='none'
                        document.getElementById('SCENE2').style.display='none'
                        document.getElementById('SCENE3').style.display='none'
                        document.getElementById('RoomKabi').style.display='none'
                        document.getElementById('RoomVamed').style.display='none'
                        document.getElementById('RoomHelios').style.display='none'
                        document.getElementById('RoomMC').style.display='block'
                        document.getElementById('lorem1').classList.remove('active1')
                        document.getElementById('lorem2').classList.remove('active2')
                        document.getElementById('lorem3').classList.remove('active3')
        
                        this.cam.cameraAnimations.CameraToMC()
                        
                        break;   
            default:
                break;
        }
    }


    changeLight(boton){
        switch (boton) {
            case 'room1':
                this.world.environment.receptionLight.color.set('#001da0')
                break;
             case 'room2':
                this.world.environment.receptionLight.color.set('#000864')
                break;
            case 'room3':
                    this.world.environment.receptionLight.color.set('#003212')
                break;
                
            default:
                this.world.environment.receptionLight.color.set('#003a96')
                break;
        }
        
    }


}
