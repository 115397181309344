export default [
    {
        name:'environmentMapTexture',
        type:'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {
        name:'mapSkyTexture',
        type:'cubeTexture',
        path:
        [
            'textures/environmentMap/skymap/px.png',
            'textures/environmentMap/skymap/nx.png',
            'textures/environmentMap/skymap/py.png',
            'textures/environmentMap/skymap/ny.png',
            'textures/environmentMap/skymap/pz.png',
            'textures/environmentMap/skymap/nz.png'
        ]
    },
    {
        name:'FreseniusMCTexture',
        type:'texture',
        path:'textures/fresenius/fresenius-medical-care.png'
    },
    {
        name:'FreseniusModelV7',
        type:'gltfModel',
        path:'models/PrototypeModel_v2/PrototypeExport2_BlenderUnpackedRepacked.glb'
    },
    {
        name:'docImage',
        type:'texture',
        path:'textures/doctora.jpg'
    },
    {
        name:'docImage2',
        type:'texture',
        path:'textures/doctor.jpg'
    },
    {
        name:'suitMan',
        type:'texture',
        path:'textures/suitman.jpg'
    },
    // {
    //     name:'transparentImage0',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00000.png'
    // },
    // {
    //     name:'transparentImage1',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00001.png'
    // },
    // {
    //     name:'transparentImage2',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00002.png'
    // },
    // {
    //     name:'transparentImage3',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00003.png'
    // },
    // {
    //     name:'transparentImage4',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00004.png'
    // },
    // {
    //     name:'transparentImage5',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00005.png'
    // },
    // {
    //     name:'transparentImage6',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00006.png'
    // },
    // {
    //     name:'transparentImage7',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00007.png'
    // },
    // {
    //     name:'transparentImage8',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00008.png'
    // },
    // {
    //     name:'transparentImage9',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00009.png'
    // },
    // {
    //     name:'transparentImage10',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00010.png'
    // },
    // {
    //     name:'transparentImage11',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00011.png'
    // },
    // {
    //     name:'transparentImage12',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00012.png'
    // },
    // {
    //     name:'transparentImage13',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00013.png'
    // },
    // {
    //     name:'transparentImage14',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00014.png'
    // },
    // {
    //     name:'transparentImage15',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00015.png'
    // },
    // {
    //     name:'transparentImage16',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00016.png'
    // },
    // {
    //     name:'transparentImage17',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00017.png'
    // },
    // {
    //     name:'transparentImage18',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00018.png'
    // },
    // {
    //     name:'transparentImage19',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00019.png'
    // },
    // {
    //     name:'transparentImage20',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00020.png'
    // },
    // {
    //     name:'transparentImage21',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00021.png'
    // },
    // {
    //     name:'transparentImage22',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00022.png'
    // },
    // {
    //     name:'transparentImage23',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00023.png'
    // },
    // {
    //     name:'transparentImage24',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00024.png'
    // },
    // {
    //     name:'transparentImage25',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00025.png'
    // },
    // {
    //     name:'transparentImage26',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00026.png'
    // },
    // {
    //     name:'transparentImage27',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00027.png'
    // },
    // {
    //     name:'transparentImage28',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00028.png'
    // },
    // {
    //     name:'transparentImage',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00029.png'
    // },
    // {
    //     name:'transparentImage',
    //     type:'texture',
    //     path:'textures/transparency_testfile/transparency_testfile_00152.png'
    // },

]