import Experience from "./Experience";
import * as THREE from 'three'
export default class Renderer
{
    constructor()
    {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera

        this.setInstance()
    }
        setInstance()
        {
            this.instance = new THREE.WebGLRenderer({
                canvas: this.canvas,
                antialias:true,
               
            })
            this.instance.physicallyCorrectLights = true
            this.instance.outputEncoding = THREE.sRGBEncoding
            // this.instance.toneMapping = THREE.CineonToneMapping
            // this.instance.toneMapping = THREE.ReinhardToneMapping
            this.instance.toneMapping = THREE.ACESFilmicToneMapping
            // this.instance.toneMappingExposure = 2
            this.instance.toneMappingExposure = 1.75
            this.instance.shadowMap.enabled = true
            this.instance.shadowMap.type = THREE.PCFSoftShadowMap
            this.instance.setClearColor('#ffffff')  
            this.instance.setSize(this.sizes.width, this.sizes.height)
            this.instance.setPixelRatio(this.sizes.pixelRatio)


            if(this.experience.debug.active){
   
                this.experience.debug.ui.add(this.instance,'toneMapping', {
                    No: THREE.NoToneMapping,
                    Linear : THREE.LinearToneMapping,
                    reinhart: THREE.ReinhardToneMapping,
                    Cineon: THREE.CineonToneMapping,
                    ACESFilmic: THREE.ACESFilmicToneMapping
                })
        
                this.experience.debug.ui.add(this.instance,'toneMappingExposure').min(0).max(2).step(0.001)
                }
            
        }
    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio) 
    }
    update()
    {
        this.instance.render(this.scene, this.camera.instance)
    }
}