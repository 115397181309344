import * as THREE from 'three'
import { gsap } from 'gsap';
import Experience from "./Experience";
export default class CameraAnimations
{
    constructor(instance)
    {   
         this.camPosition = 0
         this.camDone ={true:true}
         this.experience = new Experience()
         this.debug = this.experience.debug   
         this.instance = instance
         this.aimBox 
         this.setScroll()

         
    }

    /**
 * CAMERA GSAP ANIMATIONS
 */

update(){
    // switch (this.camPosition) {
    //     case 0:
    //         this.instance.lookAt(1.5,1.5,-1)
    //         break;
    //     case 1:
    //         this.instance.lookAt(1.5,1.5,-15)
    //         break;
    //     default:
    //         break;
    // }
    if(this.aimBox)this.instance.lookAt(this.aimBox.position)
    if(!this.aimBox)this.aimBox = this.experience.camera.aimbox
}
CameraToRoom1()
{   
   
    this.aimBox = this.experience.camera.aimbox
    
    this.select = document.getElementById('select')
    this.selectValue = this.select.value
    console.log(this.selectValue);
    this.experience.world.freseniusDisplay.changeImage(this.selectValue)
    if(this.debug.active){
        if(this.aimBox){
            this.debug.ui.add(this.aimBox.position,'x').min(-30).max(30).step(0.001).name('aimbox Position x')
            this.debug.ui.add(this.aimBox.position,'y').min(-10).max(20).step(0.001).name('aimbox Position y')
            this.debug.ui.add(this.aimBox.position,'z').min(-30).max(30).step(0.001).name('aimbox Position z')
            this.debug.ui.add(this.aimBox.rotation,'y').min(-10).max(20).step(0.001).name('aimbox rotation ')
        }
        

    }

    switch (this.camPosition) {
        case 0:
                
                    gsap.to(
                        this.aimBox.position,{
                            duration: 2.5,
                            ease: 'power2.inOut',
                            z: '-=14',
                        }
                    )
                    gsap.to(
                        this.instance.position,{
                            duration: 2.5,
                            ease: 'power2.inOut',
                            z: '-=14',
                            onComplete: ()=>{
                                this.camPosition=1
                               
                            } 
                        })
                        
            break;
        case 2:
            if (this.door&&this.doorDown) {
                gsap.to(
                    this.door.position,{
                        duration:2.5,
                        ease: 'power2.inOut',
                        z: '-= 650' 
                    })
                    this.doorDown= false
            }
            gsap.to(
                this.instance.position,{
                    duration: 2.5,
                    ease: 'power2.inOut',
                    z: '-=3',
                    onComplete: ()=>{
                                this.camPosition=1
                            } 
                })   
                gsap.to(
                    this.aimBox.position,{
                        duration: 2.5,
                        ease: 'power2.inOut',
                        x:'+=8',
                        z: '-=5.2',
                        onComplete: ()=>{
                        console.log(this.aimBox.position);
                        }  
                    }
                )   
            // gsap.to(
            //             this.instance.rotation,{
            //                 duration:2.5,
            //                 ease: 'power2.inOut',
            //                 y: '-= 1' 
            //                 ,
            //              onComplete:()=>{
            //                  this.camPosition = 1
            //             }
            //         }) 
            break; 
        case 3:
            if (this.door2&&this.door2Down) {
                gsap.to(
                    this.door2.position,{
                        duration:2.5,
                        ease: 'power2.inOut',
                        z: '-= 650' 
                    })
                    this.door2Down= false
            }
            gsap.to(
                this.instance.position,{
                    duration: 2.5,
                    ease: 'power2.inOut',
                    z: '-=3' ,
                    onUpdate: ()=>{
                       
                     } 
                })
                gsap.to(
                    this.aimBox.position,{
                        duration: 2.5,
                        ease: 'power2.inOut',
                        x:'-=10.5',
                        z: '-=5.5',
                        onComplete: ()=>{
                        console.log(this.aimBox.position);
                        this.camPosition=1
                        }  
                    }
                ) 
            // gsap.to(
            //             this.instance.rotation,{
            //                 duration:2.5,
            //                 ease: 'power2.inOut',
            //                 y: '+= 1' 
            //                 ,
            //          onComplete:()=>{
            //             this.camPosition = 1
            //          }
            //         }) 
            break;                    
        default:
            break;
    }

    
                
}
CameraToRoom2()
{
    switch (this.camPosition) {
        case 0:
            gsap.to(
                this.instance.position,{
                    duration: 2,
                    ease: 'power2.inOut',
                    z: '-=11' ,
                    onComplete: ()=>{ 
                        this.camPosition=2                     
                     } 
                })
                gsap.to(
                    this.instance.rotation,{
                        duration:2.6,
                        ease: 'power2.inOut',
                        y: '+= 1' 
                }) 
                
            break;
        case 1:
            if(this.door){
                
            }
            console.log(this.aimBox.position);
            gsap.to(
                this.aimBox.position,{
                    duration: 2.5,
                    ease: 'power2.inOut',
                    x:'-=8',
                    z: '+=5.2',
                    onComplete: ()=>{
                    console.log(this.aimBox.position);
                    this.camPosition=2
                    }  
                }
            )    
            gsap.to(
                this.instance.position,{
                    duration: 2,
                    ease: 'power2.inOut',
                    z: '+=3',
                    onUpdate: ()=>{
                      
                     }  
                })
               
            // gsap.to(
            //     this.instance.rotation,{
            //         duration:2.5,
            //         ease: 'power2.inOut',
            //         y: '+= 1' 
            // }) 
            // (-7.6,1.5,-9.8)
            //  1.5,1.5,-15
            
            break; 
        case 3:
            const tl = gsap.timeline()
            tl.to(
                this.aimBox.position,{
                    duration: 1.25,
                    ease: 'power2.inOut',
                    x:'-=10.5',
                    z: '-=5.5',
                    onComplete: ()=>{
                        console.log('first half');
                        
                    }  
                }) 
            .to(
                this.aimBox.position,{
                    duration: 1.25,
                    ease: 'power2.inOut',
                    x:'-=8',
                    z: '+=5.2',
                    onComplete: ()=>{
                    console.log('second half');
                    this.camPosition=2
                    }  
                }) 
            // gsap.to(
            //     this.instance.rotation,{
            //         duration:2.5,
            //         ease: 'power2.inOut',
            //         y: '+= 2',
            //         onUpdate: ()=>{
                       
            //          }  
            //     }) 
            break;  
            case 6:
                gsap.to(
                    this.instance.position,{
                        duration: 2,
                        ease: 'power2.inOut',
                        x: '+=25',
                        z: '+=4.5',
                        onUpdate: ()=>{
                          
                         }  
                    })
                    gsap.to(
                        this.aimBox.position,{
                            duration: 2.5,
                            ease: 'power2.inOut',
                            x:'+=29.5',
                            z:'-=1.3',
                            onComplete: ()=>{
                            console.log(this.aimBox.position);
                            }  
                        }
                    )
            break;

        default:
            break;
    }
    
       this.camPosition=2
             
}
CameraToRoom3()
{
    switch (this.camPosition) {
        case 0:
            gsap.to(
                this.instance.position,{
                    duration: 2,
                    ease: 'power2.inOut',
                    z: '-=11',
                    onComplete: ()=>{
                        this.camPosition=1
                     }   
                })
                gsap.to(
                    this.instance.rotation,{
                        duration:2.6,
                        ease: 'power2.inOut',
                        y: '-= 1' 
                }) 
            break;
        case 1:
            gsap.to(
                this.instance.position,{
                    duration: 2,
                    ease: 'power2.inOut',
                    z: '+=3' ,
                    onUpdate: ()=>{
                       
                     }  
                })
                //  1.5,1.5,-15
                gsap.to(
                    this.aimBox.position,{
                        duration: 2.5,
                        ease: 'power2.inOut',
                        x:'+=10.5',
                        z: '+=5.5',
                        onComplete: ()=>{
                        console.log(this.aimBox.position);
                        console.log(this.instance.position);
                        this.camPosition=3
                        
                        }  
                    }
                ) 
            // gsap.to(
            //     this.instance.rotation,{
            //         duration:2.5,
            //         ease: 'power2.inOut',
            //         y: '-= 1' 
            // }) 
            break; 
        case 2:
            const tl = gsap.timeline()
            tl.to(
                this.aimBox.position,{
                    duration: 1.25,
                    ease: 'power2.inOut',
                    x:'+=8',
                    z: '-=5.2',
                    onComplete: ()=>{
                    console.log(this.aimBox.position);
                    }  
                }
            )  
            .to(
                this.aimBox.position,{
                    duration: 1.25,
                    ease: 'power2.inOut',
                    x:'+=10.5',
                    z: '+=5.5',
                    onComplete: ()=>{
                    console.log(this.aimBox.position);
                    this.camPosition=3
                    }  
                }
            ) 
            break;   
            case 7:
                //12,1.5,-9.5
                //1.5,2,-4.5
                let CAMx = (this.instance.position.x)-1.5 
                let CAMy = (this.instance.position.y)-2
                let CAMz = (this.instance.position.z)-(-4.5) 
                let AimBoxx = (this.aimBox.position.x)-12 
                let AimBoxy = (this.aimBox.position.y)-1.5
                let AimBoxz = (this.aimBox.position.z)-(-9.5) 

                 CAMx = '-='+CAMx
                 CAMy = '-='+CAMy
                 CAMz = '-='+CAMz
                 AimBoxx = '-='+AimBoxx
                 AimBoxy = '-='+AimBoxy
                 AimBoxz =  '-='+AimBoxz 
                gsap.to(
                    this.instance.position,{
                        duration: 2,
                        ease: 'power2.inOut',
                        z: CAMz,
                        x:CAMx,
                        y:CAMy,
                        onUpdate: ()=>{
                           
                         }  
                    })
                    //  1.5,1.5,-15
                    gsap.to(
                        this.aimBox.position,{
                            duration: 2.5,
                            ease: 'power2.inOut',
                            x:AimBoxx ,
                            z: AimBoxz ,
                            y:AimBoxy ,
                            onComplete: ()=>{
                            console.log(this.aimBox.position);
                            console.log(this.instance.position);
                            this.camPosition=3
                            
                            }  
                        }
                    ) 


                break;          
        default:
            break;
    }
        // this.camPosition=3
             
}



CameraToReception()
{
    this.camDone.true=null
    switch (this.camPosition) {
        case 2:
       
        gsap.to(
            this.aimBox.position,{
                duration: 2.5,
                ease: 'power2.inOut',
                x:'+=8',
                z: '+=8.3',
                onComplete: ()=>{
                console.log(this.aimBox.position);
                this.camPosition=0
                
                }  
            }
        ) 
            //  gsap.to(
            //     this.instance.rotation,{
            //         duration:2.5,
            //         ease: 'power2.inOut',
            //         y: '-= 1' 
            //     })
                gsap.to(
                            this.instance.position,{
                                duration:2.5,
                                ease: 'power2.inOut',
                                 z: '+=11'
        
                        })   
                gsap.to(this.camDone,{
                    duration:2.5,
                    true:true
                })        
            break;
        case 1:
            gsap.to(
                this.instance.position,{
                    duration: 2.5,
                    ease: 'power2.inOut',
                    z: '+=14' 
                }
                )
                gsap.to(
                    this.aimBox.position,{
                        duration: 2.5,
                        ease: 'power2.inOut',
                        
                        z: '+=14',
                        onComplete: ()=>{
                            this.camPosition=0
                        
                        }  
                    }
                ) 
                // gsap.to(this.camDone,{
                //     duration:2.5,
                //     true:true
                //1.5,1.5,-1
                // })   
            break; 
        case 3:
            // gsap.to(
            //     this.instance.rotation,{
            //         duration:2.5,
            //         ease: 'power2.inOut',
            //         y: '+= 1' 
            //     })
            gsap.to(
                this.aimBox.position,{
                    duration: 2.5,
                    ease: 'power2.inOut',
                    x:'-=10.5',
                    z: '+=8.5',
                    onComplete: ()=>{
                    console.log(this.aimBox.position);
                    
                    }  
                }
            ) 
            gsap.to(
                    this.instance.position,{
                    duration:2.5,
                    ease: 'power2.inOut',
                    z: '+=11'
                    })  
            // gsap.to(this.camDone,{
            //         duration:2.5,
            //         true:true
            //     })    
            break;
        case 5:
            this.instance.position.set(1.5,2,6.5);
            this.camPosition=0  
            break;
                     
        default:
            this.instance.position.set(1.5,2,6.5);
            this.aimBox.position.set(1.5,1.5,-1)
            this.camPosition=0  
            break;
    }
        this.camPosition=0  
        
             
}

CameraToHelios(){
    switch (this.camPosition) {
        
        // case 2:
        //     gsap.to(
        //         this.instance.position,{
        //             duration: 2.5,
        //             ease: 'power2.inOut',
        //             z: '-=21'    
        //         })
        //         gsap.to(
        //             this.instance.rotation,{
        //                 duration:3,
        //                 ease: 'power2.inOut',
        //                 y: '+= 0.7' 
        //             })
              
            
           
        //     break;
        default:
            this.instance.position.set(10,1.5,-23)
            this.aimBox.position.set(10,1.5,-27)
            break;
    }
    this.camPosition=10
    
}

CameraToKabi(){
    switch (this.camPosition) {
       
            // case 2:
            //     gsap.to(
            //         this.instance.position,{
            //             duration: 2.5,
            //             ease: 'power2.inOut',
            //             z: '-=21'    
            //         })
            //         gsap.to(
            //             this.instance.rotation,{
            //                 duration:2.5,
            //                 ease: 'power2.inOut',
            //                 y: '-= 0.7' 
            //             })
            //      break;       
        default:
            this.instance.position.set(-7.8,1.5,-24)
            this.aimBox.position.set(-8.2,1.5,-27)
        
            break;
    }
    
    this.camPosition=10
    
}
CameraToVamed(){
    this.mesh1 = this.experience.world.freseniusDisplay.shape1
    this.mesh2 = this.experience.world.freseniusDisplay.shape2
    this.mesh3 = this.experience.world.freseniusDisplay.shape3  

    this.mesh1.position.y = -0.7
    this.mesh2.position.y = -0.7
    this.mesh3.position.y = -0.7
    switch (this.camPosition) {
        case 3:
   
        
        window.scrollTo(top) 
        // this.scrollDifference=0
         this.door2 =  this.experience.world.freseniusDisplay.door2
         this.door2Down = true  
         gsap.to(
             this.door2.position,{
                 duration:2.5,
                 ease: 'power2.inOut',
                 z: '+= 650' 
             })
             gsap.to(this.instance.position,{
                duration: 2.5,
                ease: 'power2.inOut',
                z:'-=3',
                onComplete: ()=>{
    
                console.log(this.aimBox.position);

               console.log(this.instance.position);
                }  
            })  
            
         
         this.camPosition=7
               
             break; 
         case 1:
             this.CameraToRoom3();
             setTimeout(()=>{this.CameraToVamed();},2500)   
             break; 
         case 2:
             this.CameraToRoom3();
             setTimeout(()=>{this.CameraToVamed();},2500)   
             break; 
                      
         default:
             this.instance.position.set(28.5,1.5,-8.5)
             this.aimBox.position.set(36,1.5,-8.5)         
             this.camPosition=7
             break;
     }
    this.camPosition=7
}

CameraToMC(){
    switch (this.camPosition) {
       case 2:
        
        console.log('entra en el swich');
        const tl = gsap.timeline()
        const tl2 = gsap.timeline()
        
        this.door =  this.experience.world.freseniusDisplay.door
        this.doorDown = true 
        tl.to(
            this.door.position,{
                duration:2.5,
                ease: 'power2.inOut',
                z: '+= 650' 
            })
            .to(
                this.aimBox.position,{
                    duration: 3,
                    ease: 'power2.inOut',
                    x:'-=22',
                    z:'-=0.5',
                    onComplete: ()=>{
                    console.log(this.aimBox.position);
                    }  
                }
            )
       
             
       tl2.to(
                this.aimBox.position,{
                    duration: 2.5,
                    ease: 'power2.inOut',
                    x:'-=7.5',
                    z: '+=1.8',
                    onComplete: ()=>{
                    console.log(this.aimBox.position);
                    }  
                }
            ) 
            .to(this.instance.position,{
                duration: 3,
                ease: 'power2.inOut',
                x:'-=25',
                z:'-=1.5',
                onComplete: ()=>{
                console.log(this.aimBox.position);
                console.log(this.instance.position);
                }  
            })
        gsap.to(this.instance.position,{
            duration: 2.5,
            ease: 'power2.inOut',
            z:'-=3',
            onComplete: ()=>{

            console.log(this.aimBox.position);
           
            }  
        })    
        this.camPosition=6
              
            break; 
        case 1:
            this.CameraToRoom2();
            setTimeout(()=>{this.CameraToMC();},2500)   
            break; 
        case 3:
            this.CameraToRoom2();
            setTimeout(()=>{this.CameraToMC();},2500)   
            break; 
                     
        default:
            this.instance.position.set(-23.5,1.5,-8.5)
            this.aimBox.position.set(-36,1.5,-8.5)         
            this.camPosition=6
            break;
    }
    
    // this.camPOSI=6
    
}

setScroll()
{
         
    this.scrolly = window.scrollY 
   
        window.addEventListener('scroll',()=>
    {
        if(this.scrolly!=null){
            
            this.up = this.scrolly < window.scrollY ? true:false
            
        }
        // console.log(window.scrollY);
        this.scrollDifference = this.scrolly - window.scrollY
        this.scrolly = window.scrollY
        console.log(this.scrollDifference);
        
        // this.scrollObjects()
        this.scrollCamera()
        
        // if(this.experience.world.freseniusDisplay.form1.position.y >=1.5 ){
        //     console.log(this.experience.world.freseniusDisplay.form1.position.y + 'cubo');
        //     this.experience.world.freseniusDisplay.form1.position.y += this.scrollDifference * 0.001
        // }
        

    })
    
} 
scrollCamera(){
    this.mesh1 = this.experience.world.freseniusDisplay.shape1
    this.mesh2 = this.experience.world.freseniusDisplay.shape2
    this.mesh3 = this.experience.world.freseniusDisplay.shape3  
    //going forward 
    if(this.camPosition==7 && this.scrollDifference<400 ){
        if(this.instance.position.x < 31.5 && this.instance.position.x > 1 && this.up){
           
            if( this.aimBox.position.x<24.5){
                console.log('fase 1');
                this.aimBox.position.x+= this.scrollDifference*(-0.002)
                this.instance.position.x+= this.scrollDifference*(-0.002)
            }
            if(this.aimBox.position.x<28 &&this.aimBox.position.x>=24.5&&this.aimBox.position.z>-12){
                console.log('fase 2');
                console.log(this.instance.position.x);
                this.aimBox.position.z+= this.scrollDifference*(0.001)
                this.instance.position.x+= this.scrollDifference*(-0.001)
            }
            if(this.instance.position.x<24.5&&this.aimBox.position.z<=-12){   
                console.log('fase 3');          
                this.instance.position.x+= this.scrollDifference*(-0.001)
            }
            if(this.instance.position.x>=24.5&&this.instance.position.x<27.4){  
                console.log('fase 4');           
                this.instance.position.x+= this.scrollDifference*(-0.001)
                this.aimBox.position.x+= this.scrollDifference*(-0.001)
                if(this.aimBox.position.y>1)this.aimBox.position.y-= this.scrollDifference*(-0.0001)
                
            }
            if(this.instance.position.x>=27.4&&this.instance.position.x<30.5){  
                console.log('fase 5');           
                this.instance.position.x+= this.scrollDifference*(-0.001)
                this.aimBox.position.x+= this.scrollDifference*(-0.001)
                if(this.aimBox.position.y<1.5)this.aimBox.position.y+= this.scrollDifference*(-0.0001)
                
            }
            if(this.instance.position.x>=30.5&&this.aimBox.position.x<36&&this.aimBox.position.z<-8.5){             
                console.log('fase 6');
                this.aimBox.position.x += this.scrollDifference*(-0.001)
                this.aimBox.position.z += this.scrollDifference*(-0.001)
                
            }

            //going backwards
        }else  if(this.instance.position.x < 33 && this.instance.position.x > 1.5 && !this.up){
            console.log('patras');
            if( this.instance.position.x<=16.7 && this.aimBox.position.z> -8.5){
                console.log('fase -1');
                this.aimBox.position.x+= this.scrollDifference*(-0.002)
                this.instance.position.x+= this.scrollDifference*(-0.002)
            }
            if(this.instance.position.x<=16.7 && this.aimBox.position.z<=-8.5){
                console.log('fase -2');
                this.aimBox.position.z+= this.scrollDifference*(0.001)
                this.instance.position.x+= this.scrollDifference*(-0.001)
            }
            if(this.instance.position.x>16.7&&this.instance.position.x<=24.5){   
                console.log('fase -3');          
                this.instance.position.x+= this.scrollDifference*(-0.001)
            }
            if(this.instance.position.x>24.5&&this.instance.position.x<=27.4){  
                console.log('fase -4');           
                this.instance.position.x+= this.scrollDifference*(-0.001)
                this.aimBox.position.x+= this.scrollDifference*(-0.001)
                if(this.aimBox.position.y<1.5)this.aimBox.position.y-= this.scrollDifference*(-0.0001)
                
            }
            if(this.instance.position.x>27.4&&this.aimBox.position.z<=-12){  
                console.log('fase -5');           
                this.instance.position.x+= this.scrollDifference*(-0.001)
                this.aimBox.position.x+= this.scrollDifference*(-0.001)
                if(this.aimBox.position.y>1)this.aimBox.position.y+= this.scrollDifference*(-0.0001)
                
            }
            if(this.instance.position.x>=30.5&&this.aimBox.position.z>-12){             
                console.log('fase -6');
                this.aimBox.position.x += this.scrollDifference*(-0.001)
                this.aimBox.position.z += this.scrollDifference*(-0.001)
                
            }
        }
        if(this.instance.position.x >= 30 &&this.aimBox.position.z>-10 &&this.instance.position.x > 1 && this.up){
            
            if(this.mesh1.position.y<1){
                this.mesh1.position.y += this.scrollDifference*(-0.001)
                this.mesh2.position.y += this.scrollDifference*(-0.001)
                this.mesh3.position.y += this.scrollDifference*(-0.001)
            }else if(this.mesh1.position.y<1.3){
                this.mesh1.position.y += this.scrollDifference*(-0.001)
                this.mesh2.position.y += this.scrollDifference*(-0.001)
                this.mesh3.position.y += this.scrollDifference*(-0.001)

                this.mesh1.rotation.y += this.scrollDifference*(-0.001)
                this.mesh1.rotation.z += this.scrollDifference*(-0.001)
                        
                this.mesh2.rotation.x += this.scrollDifference*(-0.001)
                this.mesh2.rotation.z += this.scrollDifference*(-0.001)
                
                this.mesh3.rotation.y += this.scrollDifference*(-0.001)
                this.mesh3.rotation.z += this.scrollDifference*(-0.001)
            }else if(this.mesh1.position.y<1.5){
                this.mesh1.position.y += this.scrollDifference*(-0.001)
                this.mesh2.position.y += this.scrollDifference*(-0.001)
                this.mesh3.position.y += this.scrollDifference*(-0.001)

                this.mesh1.rotation.y += this.scrollDifference*(-0.001)
                this.mesh1.rotation.z += this.scrollDifference*(-0.001)
                         
                this.mesh2.rotation.x += this.scrollDifference*(-0.001)
                this.mesh2.rotation.z += this.scrollDifference*(-0.001)
                
                this.mesh3.rotation.y += this.scrollDifference*(-0.001)
                this.mesh3.rotation.z += this.scrollDifference*(-0.001)
            }
            
            this.mesh1.rotation.y += this.scrollDifference*(-0.001)
            this.mesh1.rotation.z += this.scrollDifference*(-0.001)
                     
            this.mesh2.rotation.x += this.scrollDifference*(-0.001)
            this.mesh2.rotation.z += this.scrollDifference*(-0.001)
            
            this.mesh3.rotation.y += this.scrollDifference*(-0.001)
            this.mesh3.rotation.z += this.scrollDifference*(-0.001)
        }
        if(this.instance.position.x >= 30 && this.instance.position.x > 1 && !this.up){
            
           if(this.mesh1.position.y>-0.7){
            this.mesh1.position.y += this.scrollDifference*(-0.001)
            this.mesh2.position.y += this.scrollDifference*(-0.001)
            this.mesh3.position.y += this.scrollDifference*(-0.001)
           }
        }
       

        
    }
        
    
}

}    