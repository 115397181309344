import * as THREE from 'three'
import Experience from "../Experience";
import Environment from './Environment';
import FreseniusSpace from './FreseniusSpace';

export default class World
{
    constructor()
        {
            this.experience = new Experience()
            this.scene = this.experience.scene
            this.resources = this.experience.resources
            this.freseniusDisplay = null
            this.environment = null
            
           
           
             this.resources.on('ready',()=>
             {
               //setup
                this.freseniusDisplay = new FreseniusSpace()
                this.environment = new Environment()
                             
                
             })
           
        }
        update()
        {
            if(this.freseniusDisplay)this.freseniusDisplay.update()   
            
        }

}