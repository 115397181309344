import EventEmitter from "./EventEmitter";
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { gsap } from 'gsap'
import Experience from "../Experience";
export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()
        this.sources = sources
        this.experience = new Experience()
        this.scene = this.experience.scene
        //Setup
        this.fillSourcesTransparency()
        this.items={}
        this.toLoad = this.sources.length
        this.loaded = 0
        this.setLoaders()
        this.loading()
        this.startLoading()
    }

    setLoaders()
    {
        this.loaders={}
        this.loadingBarElement = document.getElementById('loading-bar')
        // this.loadingText = document.getElementById('intro')
        this.loadingManager = new THREE.LoadingManager(
            ()=>{
                window.setTimeout(()=>
                {
                console.log('loaded');
                gsap.to(this.overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
                this.loadingBarElement.classList.add('ended')
                this.loadingBarElement.style.transform = ''
                // this.loadingText.style.display = 'none'
                // this.loadingBarElement.style.transform = ''
                },500)
                
            },
            (itemUrl, itemsLoaded, itemsTotal)=>{
                console.log('loading');
                const progressRatio = itemsLoaded / itemsTotal
                this.loadingBarElement.style.transform = `scaleX(${progressRatio})`              
            
            })

        this.loaders.dracoLoader = new DRACOLoader(this.loadingManager)
        this.loaders.dracoLoader.setDecoderPath('/draco/')
        this.loaders.gltfLoader = new GLTFLoader(this.loadingManager)
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)
        this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingManager)
        
    }
    startLoading()
    {
        for(const source of this.sources){
            if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) => 
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }else if(source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) => 
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) => 
                    {
                     
                        this.sourceLoaded(source, file)
                    }
                )
            }
        }
    }


    sourceLoaded(source,file)
    {
        this.items[source.name] = file
        this.loaded++
        if(this.loaded==this.toLoad)
        {   
            // console.log('finish');
            this.trigger('ready')
        }
    }

    loading()
        {
            this.overlayMaterial = new THREE.ShaderMaterial({
                // ...
                vertexShader: `
                    void main()
                    {
                        gl_Position = vec4(position, 1.0);
                    }
                `,
                transparent:true,
                uniforms:
                    {
                        uAlpha: { value: 1 }
                    },
                fragmentShader: `
                    uniform float uAlpha;
            
                    void main()
                    {
                        gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
                    }
                `
            })
            this.overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
            this.overlay = new THREE.Mesh(this.overlayGeometry, this.overlayMaterial)
            this.scene.add(this.overlay)
        }
        fillSourcesTransparency(){
            // this.sources.add({
            //     name:'transparentImage200',
            //     type:'texture',
            //     path:'textures/transparency_testfile/transparency_testfile_00200.png'
            // })
            for (let i=0;i<58;i++) {
                let completo=false
                let a= ''
                let n =0
                a=a+i
                while(!completo&&n<=10){
                    
                    
                    if(a.length==2){
                        completo=true
                    }else{
                        a='0'+a
                        
                    }
                    n++
                }
                
                let imageName = 'transparentImage'+i
                let imagePath = 'textures/transparencyTest/frame_'+a+'_delay-0.04s.png'
                console.log(imageName);
                console.log(imagePath);
                this.sources.push({type:'texture', name:imageName, path:imagePath})

            }
         
            // for (let i=0;i<50;i++) {
            //     let completo=false
            //     let a= 1
            //     let n =0
            //     a=a+i
            //     while(!completo&&n<=10){
                    
                    
            //         if(a.length==3){
            //             completo=true
            //         }else{

            //             a='0'+a
                        
            //         }
            //         n++
            //     }
                
            //     let imageName = 'transparentImage'+i
            //     let imagePath = 'textures/earth/ezgif-frame-'+a+'.png'
            //     console.log(imageName);
            //     console.log(imagePath);
            //     this.sources.push({type:'texture', name:imageName, path:imagePath})

            // }
            
        }

}
