import * as THREE from 'three'
import Sizes from './Utils/Sizes'
import Time from './Utils/Time'
import Camera from './Camera'
import Renderer from './Renderer'
import World from './World/World'
import Resources from './Utils/Resources'
import sources from './sources'
// import devSources from './devSources'
import Debug from './Utils/Debug'
import Events from './Utils/Events'

let instance = null
export default class Experience{
 
    constructor(canvas)
    {

        if(instance){
            return instance
        }
        instance=this
        //Golbal acces 
        window.experience = this
        
        //Options
        this.canvas = canvas
        
        //Setup
        
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        // this.resources = new Resources(devSources)
        this.camera = new Camera(this)
        this.renderer = new Renderer()
        this.world = new World()
        this.events = new Events()

        
        //sizes resize event
        this.sizes.on('resize',()=>{
            this.resize()
        })
        

        //time tick event
        this.time.on('tick',()=>{
            this.update()
        })
    }

    resize()
    {
       this.camera.resize()
       this.renderer.resize()
    }
    
    update()
    {
        this.camera.update()
        this.renderer.update()
        this.world.update()
        this.events.update()
    }
}
