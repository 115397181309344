import * as THREE from 'three'
import Experience from "./Experience";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { gsap } from 'gsap';
import CameraAnimations from './CameraAnimations';
export default class Camera
{
    constructor()
    {   
         this.camPOSI = 0
         this.camDone ={true:true}
         this.experience = new Experience()
         this.sizes = this.experience.sizes
         this.scene = this.experience.scene
         this.canvas = this.experience.canvas
         this.debug = this.experience.debug
         this.aimbox
         this.setInstance()
         this.setParalax()
         this.setCameraAnimations(this.instance)
        
        //  this.setOrbitControls()
        
         
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width/ this.sizes.height,
            0.1,
            100
        )
        this.instance.position.set(1.5,2,6.5)
        if(this.debug.active){
            this.debug.ui.add(this.instance.position,'x').min(-50).max(50).step(0.001).name('camara pos x')
            this.debug.ui.add(this.instance.position,'y').min(-20).max(20).step(0.001).name('camara pos y')
            this.debug.ui.add(this.instance.position,'z').min(-100).max(100).step(0.001).name('camara pos z')
        }
        this.cameraGroup = new THREE.Group()

        
        this.cameraGroup.add(this.instance)
        this.scene.add(this.cameraGroup)

        //set aimbox
        this.aimboxMaterial = new THREE.MeshBasicMaterial({
            color:'#ff0000',
        }
        )
        this.aimboxGeometry= new THREE.BoxGeometry(1,1,1) 
        this.aimboxMaterial.visible=false   
        this.aimbox = new THREE.Mesh(this.aimboxGeometry,this.aimboxMaterial)
        this.aimbox.scale.set(0.1,0.1,0.1)
        this.scene.add(this.aimbox)
        this.aimbox.position.set(1.5,1.5,-1)
        this.instance.lookAt(1.5,1.5,-1)
        
    }

    

    setParalax(){
        
        this.cursor = {}
        this.cursor.x = 0
        this.cursor.y = 0
    
        window.addEventListener('mousemove',(event)=>
        {
            this.cursor.x = (event.clientX / this.experience.sizes.width) -0.5
            this.cursor.y = (event.clientY /this.experience.sizes.height) -0.5
            
        })
        }

    
      
    setOrbitControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize()
    {
        this.instance.aspect = this.sizes.width/ this.sizes.height
        this.instance.updateProjectionMatrix()
    }
    update()
    {

        this.parallaxX = - this.cursor.x
        this.parallaxY =   this.cursor.y
        this.newPositionX = (this.parallaxX - this.cameraGroup.position.x) * this.experience.time.delta * 0.001
        this.newPositionY = (this.parallaxY - this.cameraGroup.position.y) * this.experience.time.delta * 0.001
        this.cameraGroup.position.x +=  this.newPositionX
        this.cameraGroup.position.y += this.newPositionY

        this.instance.lookAt(this.aimbox.position)
        this.cameraAnimations.update()


        //
        // switch (this.camPOSI){
        //     case 0:
                
        //         if(this.camDone.true==true)this.instance.lookAt(1.5,1.2,-1)
                
        //         break;
        //     case 6:

        //         // if(this.experience.world.freseniusDisplay.form1.position.y >=1.5 ){
        //         //     console.log(this.experience.world.freseniusDisplay.form1.position.y + 'cubo');
        //         //     this.experience.world.freseniusDisplay.form1.position.y -= this.scrollDifference * 0.0001
        //         // }
                
        //         if(this.up){
        //             if(this.experience.world.freseniusDisplay.form1.position.y >=1.5 )
        //             this.experience.world.freseniusDisplay.form1.rotation.y -= this.scrolly * 0.000005
        //             if(this.experience.world.freseniusDisplay.form2.position.y >=1.5 )
        //             this.experience.world.freseniusDisplay.form2.rotation.y -= this.scrolly * 0.000005
        //             if(this.experience.world.freseniusDisplay.form3.position.y >=1.5 )
        //             this.experience.world.freseniusDisplay.form3.rotation.y -= this.scrolly * 0.000005
        //         }
        //         if(!this.up){
        //             if(this.experience.world.freseniusDisplay.form1.position.y <=8 )
        //             this.experience.world.freseniusDisplay.form1.rotation.y += this.scrolly * 0.000005
        //             if(this.experience.world.freseniusDisplay.form1.position.y >=8 && this.experience.world.freseniusDisplay.form2.position.y <=12)
        //             this.experience.world.freseniusDisplay.form2.rotation.y += this.scrolly * 0.000005
        //             if(this.experience.world.freseniusDisplay.form2.position.y >=8 && this.experience.world.freseniusDisplay.form2.position.y <=16)
        //             this.experience.world.freseniusDisplay.form3.rotation.y += this.scrolly * 0.000005
        //         }
  
        //         // console.log(this.experience.world.freseniusDisplay.form1.position.y);
        //         break;
        //     default:
        //         break;
        // }
        
       
    //    this.controls.update()
    }

setCameraAnimations(camera){
    this.cameraAnimations = new CameraAnimations(camera)
}
    /**
 * CAMERA ANIMATIONS
 */


// CameraToRoom1()
// {

//     this.select = document.getElementById('select')
//     this.selectValue = this.select.value
//     console.log(this.selectValue);
//     this.experience.world.freseniusDisplay.changeImage(this.selectValue)
//     switch (this.camPOSI) {
//         case 0:
//                     gsap.to(
//                         this.instance.position,{
//                             duration: 2.5,
//                             ease: 'power2.inOut',
//                             z: '-=14' 
//                         })

//             break;
//         case 1:
//             gsap.to(
//                 this.instance.position,{
//                     duration: 2.5,
//                     ease: 'power2.inOut',
//                     z: '-=3' 
//                 })
//             gsap.to(
//                         this.instance.rotation,{
//                             duration:2.5,
//                             ease: 'power2.inOut',
//                             y: '-= 1' 
            
//                     }) 
//             break; 
//         case 3:
//             gsap.to(
//                 this.instance.position,{
//                     duration: 2.5,
//                     ease: 'power2.inOut',
//                     z: '-=3' 
//                 })
//             gsap.to(
//                         this.instance.rotation,{
//                             duration:2.5,
//                             ease: 'power2.inOut',
//                             y: '+= 1' 
                
//                     }) 
//             break;                    
//         default:
//             break;
//     }

//    this.camPOSI=2   
                
// }
// CameraToRoom2()
// {
//     switch (this.camPOSI) {
//         case 0:
//             gsap.to(
//                 this.instance.position,{
//                     duration: 2,
//                     ease: 'power2.inOut',
//                     z: '-=11' 
//                 })
//                 gsap.to(
//                     this.instance.rotation,{
//                         duration:2.6,
//                         ease: 'power2.inOut',
//                         y: '+= 1' 
//                 }) 
                
//             break;
//         case 2:
//             gsap.to(
//                 this.instance.position,{
//                     duration: 2,
//                     ease: 'power2.inOut',
//                     z: '+=3' 
//                 })
//             gsap.to(
//                 this.instance.rotation,{
//                     duration:2.5,
//                     ease: 'power2.inOut',
//                     y: '+= 1' 
//             }) 
//             break; 
//         case 3:
//             gsap.to(
//                 this.instance.rotation,{
//                     duration:2.5,
//                     ease: 'power2.inOut',
//                     y: '+= 2' 
//                 }) 
//             break;             
//         default:
//             break;
//     }
    
//         this.camPOSI=1
             
// }
// CameraToRoom3()
// {
//     switch (this.camPOSI) {
//         case 0:
//             gsap.to(
//                 this.instance.position,{
//                     duration: 2,
//                     ease: 'power2.inOut',
//                     z: '-=11' 
//                 })
//                 gsap.to(
//                     this.instance.rotation,{
//                         duration:2.6,
//                         ease: 'power2.inOut',
//                         y: '-= 1' 
//                 }) 
//             break;
//         case 2:
//             gsap.to(
//                 this.instance.position,{
//                     duration: 2,
//                     ease: 'power2.inOut',
//                     z: '+=3' 
//                 })
//             gsap.to(
//                 this.instance.rotation,{
//                     duration:2.5,
//                     ease: 'power2.inOut',
//                     y: '-= 1' 
//             }) 
//             break; 
//         case 1:
//             gsap.to(
//                 this.instance.rotation,{
//                     duration:2.5,
//                     ease: 'power2.inOut',
//                     y: '-= 2' 
//                 }) 
//             break;             
//         default:
//             break;
//     }
//         this.camPOSI=3
             
// }



// CameraToReception()
// {
//     this.camDone.true=null
//     switch (this.camPOSI) {
//         case 1:
//              gsap.to(
//                 this.instance.rotation,{
//                     duration:2.5,
//                     ease: 'power2.inOut',
//                     y: '-= 1' 
//                 })
//                 gsap.to(
//                             this.instance.position,{
//                                 duration:2.5,
//                                 ease: 'power2.inOut',
//                                  z: '+=11'
        
//                         })   
//                 gsap.to(this.camDone,{
//                     duration:2.5,
//                     true:true
//                 })        
//             break;
//         case 2:
//             gsap.to(
//                 this.instance.position,{
//                     duration: 2.5,
//                     ease: 'power2.inOut',
//                     z: '+=14' 
//                 }
//                 )
//                 gsap.to(this.camDone,{
//                     duration:2.5,
//                     true:true
//                 })   
//             break; 
//         case 3:
//             gsap.to(
//                 this.instance.rotation,{
//                     duration:2.5,
//                     ease: 'power2.inOut',
//                     y: '+= 1' 
//                 })
//             gsap.to(
//                     this.instance.position,{
//                     duration:2.5,
//                     ease: 'power2.inOut',
//                     z: '+=11'
//                     })  
//             gsap.to(this.camDone,{
//                     duration:2.5,
//                     true:true
//                 })    
//             break;
//         case 5:
//             this.instance.position.set(1.5,2,6.5);
//             this.instance.lookAt(1.5,1.5,-1)
//             break;
                     
//         default:
//             this.instance.position.set(1.5,2,6.5);
//             this.instance.lookAt(1.5,1.5,-1)
//             break;
//     }
//         this.camPOSI=0  
        
             
// }

// CameraToHelios(){
//     switch (this.camPOSI) {
        
//         // case 2:
//         //     gsap.to(
//         //         this.instance.position,{
//         //             duration: 2.5,
//         //             ease: 'power2.inOut',
//         //             z: '-=21'    
//         //         })
//         //         gsap.to(
//         //             this.instance.rotation,{
//         //                 duration:3,
//         //                 ease: 'power2.inOut',
//         //                 y: '+= 0.7' 
//         //             })
              
            
           
//         //     break;
//         default:
//             this.instance.position.set(10,1.5,-23)
//             this.camPOSI=5
//             this.instance.lookAt(10,1.3,-26.7)
//             break;
//     }
//     this.camPOSI=5
    
// }

// CameraToKabi(){
//     switch (this.camPOSI) {
       
//             // case 2:
//             //     gsap.to(
//             //         this.instance.position,{
//             //             duration: 2.5,
//             //             ease: 'power2.inOut',
//             //             z: '-=21'    
//             //         })
//             //         gsap.to(
//             //             this.instance.rotation,{
//             //                 duration:2.5,
//             //                 ease: 'power2.inOut',
//             //                 y: '-= 0.7' 
//             //             })
//             //      break;       
//         default:
//             this.instance.position.set(-8,1.5,-24)
//             this.camPOSI=6
//             this.instance.lookAt(-8,1.3,-26.7)
//             break;
//     }
    
//     this.camPOSI=6
    
// }
// CameraToVamed(){
//     switch (this.camPOSI) {
       
//             // case 2:
              
//             //      break;       
//         default:
//             this.instance.position.set(-19,1.5,-11)
//             this.camPOSI=6
//             this.instance.lookAt(-22.5,1.3,-15)
//             break;
//     }
//     window.scrollTo(top) 
//     this.experience.world.freseniusDisplay.form1.position.y = 8
//     this.experience.world.freseniusDisplay.form2.position.y = 12 
//     this.experience.world.freseniusDisplay.form3.position.y = 16
//     this.camPOSI=6
//     // this.setScroll()
// }

// CameraToMC(){
//     switch (this.camPOSI) {
       
//             // case 2:
              
//             //      break;       
//         default:
//             this.instance.position.set(24,1.5,-10)
//             this.camPOSI=6
//             this.instance.lookAt(25,1.3,-13)
//             break;
//     }
    
//     this.camPOSI=6
    
// }


// scrollObjects()
// {
//     if(this.camPOSI==6){
//          if(this.up){
//                     console.log(this.up)
//                     if(this.experience.world.freseniusDisplay.form1.position.y >=1.5 )
//                     this.experience.world.freseniusDisplay.form1.position.y += this.scrollDifference * 0.002
//                     console.log('form 1-'+this.experience.world.freseniusDisplay.form1.position.y)
//                     if(this.experience.world.freseniusDisplay.form2.position.y >=2)
//                     this.experience.world.freseniusDisplay.form2.position.y += this.scrollDifference * 0.002
//                     console.log('form 2-'+this.experience.world.freseniusDisplay.form2.position.y)
//                     if(this.experience.world.freseniusDisplay.form3.position.y >=1.5 )
//                     this.experience.world.freseniusDisplay.form3.position.y += this.scrollDifference * 0.002
//                     console.log('form 3-'+this.experience.world.freseniusDisplay.form3.position.y)
//                 }
//                 if(!this.up){
//                     console.log(this.up)
//                     if(this.experience.world.freseniusDisplay.form1.position.y <=8 )
//                     this.experience.world.freseniusDisplay.form1.position.y += this.scrollDifference * 0.001
//                     if(this.experience.world.freseniusDisplay.form1.position.y >=4 && this.experience.world.freseniusDisplay.form2.position.y <=12)
//                     this.experience.world.freseniusDisplay.form2.position.y += this.scrollDifference * 0.001
//                     if(this.experience.world.freseniusDisplay.form2.position.y >=4 && this.experience.world.freseniusDisplay.form2.position.y <=16)
//                     this.experience.world.freseniusDisplay.form3.position.y += this.scrollDifference * 0.001
//                     console.log('dobleform 3-'+this.experience.world.freseniusDisplay.form3.position.y)
//                 }
  
//     }
// }

 }




